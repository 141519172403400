import React, {useState} from 'react';
import {Layout, PageHeader} from 'antd';
import Sidebar from './components/Sidebar';
import { useTranslation } from 'react-i18next';

const {Header, Footer, Sider} = Layout;

const MainLayout = ({children}) => {
    const [collapsed, setCollapse] = useState(false);
    const { t, i18n } = useTranslation();
    const handleCollapse = collapse => {
        setCollapse(!collapsed);
    };
    return (
        <Layout style={{minHeight: '100vh'}}>
            <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={handleCollapse}
                width={270}
                collapsedWidth={82}
            >
                <div className="logo"/>
                <Sidebar collapsed={collapsed}/>
            </Sider>
            <Layout className="site-layout">
                <Header className="site-header" style={{padding: 0}}>
                    <PageHeader
                        className="site-page-header"
                        title={t('loginPage.welcome')}
                    />
                </Header>
                <div className="site-content">
                    {children}
                </div>
            </Layout>
        </Layout>
    );
};

export default MainLayout;
